
.footer {
    background: url('/public/backgrounds/footer-bg.png') center no-repeat;
    background-size: cover;
    padding: 50px 0;
    position: relative;
}


.footer .copy-right {
    color: #fff;
    text-align: center;
}

.footer .logo-container {
    background: rgba(60, 109, 148, .8);
    width: 240px;
    margin: 10px auto;
    padding: 10px;
    border-radius: 13px;
    border: 1px solid rgb(117, 251, 253);
}

.footer .logo-container img {
    width: 200px;
}

.footer .link-container {
    display: flex;
    justify-content: space-between;
}

.footer .link-container {
    color: #fff;
}

.footer .link-container a,
.footer .link-container .link {
    text-decoration: none;
    cursor: pointer;
}

.footer .link-container .blue {
    color: rgb(117, 251, 253);
}

.footer .link-container h3 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
}

.footer .link-container ul {
    margin-bottom: 20px;
}

.footer .link-container li {
    margin: 10px 0;
}

.footer .link-container li a:hover,
.footer .link-container li .link:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 850px) {

    .footer .link-container {
        text-align: center;
    }
}

@media only screen and (max-width: 750px) {

    .footer .link-container {
        flex-direction: column;
    }

    .footer .link-container .link-section {
        margin-bottom: 20px;
    }
}