
header {
    background: url('/public/backgrounds/office.png') center no-repeat;
    background-size: cover;
    width: 100%;
    height: 700px;
}

header .content {
    #height: 100%;
}

header .header-top {
    background: rgba(57, 62, 90, .95);
    width: 100%;
    height: 25px;
    text-align: right;
    padding: 0 10px;
}

header .top-items {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-top: 5px;
}

header .top-items li {
    margin: 0 20px;
    display: flex;
}

header .top-items li img {
    height: 13px;
}

header .top-items li span {
    margin-left: 7px;
    color: #f3e2c7;
    font-weight: 300;
    font-size: 12px;
}

header .header-menu {
    width: 100%;
    height: 75px;
    background: rgba(66, 84, 133, .95);
    transition: all .3s;
}

header .logo-container {
    flex-grow: 1;
}

header .logo {
    height: 50px;
    margin: 10px;
    padding: 5px;
    border: 1px solid #70e7ee;
    border-radius: 10px;
}

header .menu-container {
    margin: 10px;
    flex: 1;
    align-content: center;
}

header .menu {
    align-content: end;
    display: flex;
}

header .menu a,
header .menu span {
    color: #fff;
    text-decoration: none;
    margin: 0 20px;
    font-weight: 600;
    cursor: pointer;
    transition: all .3s;
}

header .menu a:hover,
header .menu span:hover {
    color: rgba(255, 255, 255, .7);
    transition: all .3s;
}

header .menu .contact-button {
    padding: 5px 30px;
    border: 2px solid #70e7ee;
    border-radius: 40px 0 40px 0;
    transition: all .3s;
}

header .menu .contact-button:hover {
    background: rgb(117, 251, 253);
    color: rgb(65, 126, 145);
    border: 2px solid #fff;
    transition: all .3s;
}

header .menu .home-button img {

}

header .header-info-bar {
    background: rgba(77, 80, 82, .95);
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}

header .header-info-bar p {
    color: #f3e2c7;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .8rem;
    letter-spacing: 3px;
}

header .content {
    position: relative;
    width: 100%;
    #height: calc(100% - 150px);
}

header .content:after {
    content: '';
    background: url('/public/backgrounds/huge-logo.png') right no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 99;
}

header .content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

header .img-container {
    margin-right: 100px;
    z-index: 999;
    display: flex;
    align-items: end;
}

header .img-container img {
    height: 530px;
    margin-top: 20px;
}

header .content-info {
    background: rgb(23, 25, 58);
    width: 500px;
    height: 300px;
    border-radius: 20px;
    padding: 20px;
    margin-left: 100px;
}

header .content-info .line-1 {
    color: rgb(185, 145, 94);
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
}

/*header .content-info .line-1:after {
    content: '';
    background: rgb(185, 145, 94);
    width: 260px;
    height: 16px;
    top: 5px;
    #margin-left: 10px;
    right: 0;
    position: absolute;
}*/

header .content-info .line-1-container {
    display: flex;
}

header .content-info .line-1-container .line-1-effect {
    flex: 1;
    width: 100%;
    height: 20px;
    background: rgb(185, 145, 94);
    margin-left: 10px;
    margin-top: 2px;
}

header .content-info .line-2 {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

header .content-info .line-3 {
    -webkit-text-fill-color: rgb(23, 25, 58);
    text-shadow: -1px -1px 0 rgb(117, 251, 253), 1px -1px 0 rgb(117, 251, 253), -1px 1px 0 rgb(117, 251, 253), 1px 1px 0 rgb(117, 251, 253);
    font-size: 50px;
    font-weight: 600;
    text-transform: uppercase;
}

.header-menu-small {
    display: flex;
    justify-content: flex-end;
}

.hms-icon-container {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #fff;
    display: none;
    padding-top: 1px;
}

.hms-icon-line {
    background: #fff;
    width: calc(100% - 14px);
    height: 3px;
    margin: 7px 7px;
    border-radius: 2px;
}

/*
    scroll
 */

.scrolled-header  {
    height: 625px;
}


.scrolled-header .header-menu {
    position: fixed;
    top: 0;
    z-index: 99999999;
    background: rgb(66, 84, 133);
    transition: all .3s;
}

/*
    responsive
 */

@media only screen and (max-width: 1200px) {
    header .header-info-bar p {
        font-size: .6rem;
    }
}

@media only screen and (max-width: 800px) {
    header .header-info-bar p {
        font-size: .5rem;
    }
}

@media only screen and (max-width: 1100px) {
    header .content-info {
        margin-left: 25px;
        z-index: 9999;
    }
}

@media only screen and (max-width: 1000px) {

    header .menu a, header .menu span {
        font-size: .9rem;
        margin: 0 10px;
    }

    header .img-container img {
        height: 480px;
        margin-top: 70px;
    }
}

@media only screen and (max-width: 850px) {

    header, .scrolled-header  {
        height: 500px;
    }

    header .menu a, header .menu span {
        font-size: .9rem;
        margin: 0 10px;
    }

    header .top-items {
        justify-content: center;
    }

    header .menu {
        display: none;
    }

    header .img-container {
        display: none;
    }

    header .content:after {
        display: none;
    }

    header .content-container {
        margin-top: 23px;
        justify-content: center;
        align-items: center;
    }

    .scrolled-header .content-container {
        margin-top: 100px;
    }

    .hms-icon-container {
        display: block;
    }

    @media only screen and (max-width: 700px) {
        header .top-items li span {
            font-size: 10px;
        }

        header .top-items li img {
            height: 10px;
            margin-top: 1px;
        }

        header .content-info .line-1-container .line-1-effect {
            height: 16px;
        }

    }

    @media only screen and (max-width: 700px) {
        header .content-info .line-1:after {
            width: 330px;
            top: 0;
        }

        header .header-top {
            display: none;
        }

        header .content-info {
            width: 80%;
        }

        header .content-info .line-1 {
            font-size: 1rem;
        }

        header .content-info .line-2 {
            font-size: 1.5rem;
        }

        header .content-info .line-3 {
            font-size: 3rem;
        }

        header .content-info {
            height: auto;
        }

        header {
            height: auto;
            padding-bottom: 50px;
        }

        .scrolled-header  {
            height: auto;
            padding-bottom: 50px;
        }
    }

    @media only screen and (max-width: 550px) {
        header .header-info-bar p {
            font-size: .4rem;
        }
        header .content-info .line-3 {
            font-size: 2.5rem;
        }
    }

    @media only screen and (max-width: 550px) {
        header .content-info .line-3 {
            font-size: 2rem;
        }
    }

    @media only screen and (max-width: 420px) {
        header .header-info-bar p {
            font-size: .35rem;
        }
        header .content-info .line-2 {
            font-size: 1.2rem;
        }

        header .content-info .line-3 {
            font-size: 1.8rem;
        }
    }
}