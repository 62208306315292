

.howto {
    background: url('/public/backgrounds/hogyan-mukodik-bg.png') center no-repeat;
    background-size: cover;
    padding: 50px 0;
    position: relative;
    z-index: -2;
}

.howto h2 {
    font-weight: 800;
    font-size: 26px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 20px;
    color: rgb(23, 25, 58);
    max-width: 600px;
}

.howto p {
    color: rgb(23, 25, 58);
    font-size: 18px;
}

.howto p .highlight {
    font-weight: 600;
}

.howto .howto-items-container {
    margin-top: 100px;
    position: relative;
}

.howto .howto-item {
    margin: -50px auto 0;
    background: rgb(23, 25, 58);
    border-radius: 30px;
    color: #fff;
    width: 30%;
    display: flex;
    justify-content: center;
    padding: 30px;
}

.howto .howto-item:nth-child(even) {
    margin-left: 51.5%;
}

.howto .howto-item:nth-child(odd) {
    margin-right: 51.5%;
}

.howto .howto-item .graphics {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.howto .howto-item .text h3 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
}

.howto .howto-item .text p {
    color: #fff;
    font-weight: 300;
    font-size: 16px;
}

@media only screen and (max-width: 1100px) {
    .howto .howto-item {
        width: 40%;
        text-align: left;
    }
}

@media only screen and (max-width: 850px) {
    .howto h2 {
        max-width: 100%;
        text-align: center;
    }

    .howto p {
        text-align: center;
    }

    .howto .howto-items-container {
        margin-top: 20px;
    }

    .howto .howto-item {
        width: 70%;
        text-align: left;
    }

    .howto .howto-item:nth-child(even) {
        margin: 20px auto;
    }

    .howto .howto-item:nth-child(odd) {
        margin: 20px auto;
    }

    .howto .howto-item .text p {
        text-align: left;
    }

    @media only screen and (max-width: 700px) {
        .howto .howto-item {
            width: 100%;
            text-align: left;
        }
    }
}