
:root {
  --web-view-id: null;
  --web-scale-to-fit: false;
  --web-scale-to-fit-type: default;
  --web-enable-scale-up: false;
  --web-scale-on-resize: true;
  --web-show-scale-controls: false;
  --web-scale-on-double-click: false;
  --web-center-horizontally: false;
  --web-center-vertically: false;
  --web-actual-size-on-double-click: false;
  --web-navigate-on-keypress: false;
  --web-refresh-for-changes: false;
  --web-add-image-compare: false;
  --web-show-by-media-query: false;
  --web-application: true;
  --web-show-navigation-controls: false;
  --web-enable-deep-linking: true;
  --web-is-overlay: false;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;

  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;

  font-style: normal;
  font-weight: normal;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

ul {
  list-style: none;
}

.flex-box {
  display: flex;
}
