
.contact {
    background: linear-gradient(0deg, rgba(23, 25, 58, .7), rgba(23, 25, 58, .7)), url('/public/backgrounds/1378.jpg') center no-repeat;
    background-size: cover;
    padding: 50px 0;
    position: relative;
    #box-shadow: inset 0 0 0 2000px rgba(23, 25, 58, .7);
}

.contact h2 {
    font-weight: 800;
    font-size: 26px;
    text-transform: uppercase;
    color: #fff;
    max-width: 600px;
    margin: 0 auto 20px;
    text-align: center;
}

.contact p {
    max-width: 600px;
    text-align: center;
    margin: 0 auto;
    color: #fff;
    line-height: 26px;
}

.contact .contact-title-container {
    display: flex;
    justify-content: space-around;
}

.contact .contact-title-container .call-back {
    margin-left: 100px;
    cursor: pointer;
}

.contact .contact-title-container .send-message {
    margin-right: 100px;
    cursor: pointer;
}

.contact .contact-title-container .call-back a,
.contact .contact-title-container .send-message a {
    padding: 5px 30px;
    color: rgb(65, 126, 145);
    background: rgb(117, 251, 253);
    border: 2px solid #fff;
    border-radius: 40px 0 40px 0;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
}

.contact .contact-title-container .call-back a:hover,
.contact .contact-title-container .send-message a:hover {
    color: #fff;
    background: transparent;
    border: 2px solid rgb(117, 251, 253);
}

.contact .contacts-info-container {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    text-align: center;
}

.contact .contacts-info-container .contact-info img {
    height: 30px;
}

.contact .contacts-info-container .contact-info h3 {
    font-size: 16px;
    color: #fff;
    margin-top: 10px;
}

.contact .map-container {
    margin: 50px 10px 0;

}

@media only screen and (max-width: 950px) {
    .contact .contact-title-container .call-back {
        margin-left: 0;
    }

    .contact .contact-title-container .send-message {
        margin-right: 0;
    }

    .contact p {
        max-width: 100%;
    }
}

@media only screen and (max-width: 850px) {

    .contact .contacts-info-container {
        flex-wrap: wrap;
    }

    .contact .contacts-info-container .contact-info-break {
        width: 100%;
    }

    .contact .contacts-info-container .contact-info {
        margin-bottom: 30px;
        width: 50%;
    }

    .contact .map-container {
        margin: 20px 10px 0;

    }
}

@media only screen and (max-width: 750px) {

    .contact .contact-title-container .call-back {
        display: none;
    }

    .contact .contact-title-container .send-message {
        display: none;
    }
}

@media only screen and (max-width: 400px) {

    .contact .contacts-info-container {
        flex-direction: column;
        align-items: center;
    }
}