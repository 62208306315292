
.about {
    background: url('/public/backgrounds/mit-mondanak-login-bg.png') center no-repeat;
    background-size: cover;
    padding: 50px 0;
}

.about .about-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.about .content-container {
    max-width: 50%;
    display: block;
    background: rgba(255, 255, 255, .08);
    border-radius: 10px;
    padding: 30px;
    color: #fff;
}

.about h2 {
    font-weight: 800;
    font-size: 26px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 20px;
}

.about .content-container p {
    line-height: 24px;
    font-weight: 300;
}

.about .content-container p .highlight {
    font-weight: 600;
}

.about .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 30px 0 10px 0;
}

.about .button-container .read-more a,
.about .button-container .read-more span,
.about .button-container .call-back a,
.about .button-container .call-back span {
    padding: 5px 30px;
    border: 2px solid #70e7ee;
    border-radius: 40px 0 40px 0;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
}

.about .button-container .read-more a,
.about .button-container .read-more span {
    color: rgb(65, 126, 145);
    background: rgb(117, 251, 253);
    border: 2px solid #fff;
    transition: all .3s;
}

.about .button-container .read-more a:hover,
.about .button-container .read-more span:hover {
    border: 2px solid #70e7ee;
    color: #fff;
    background: transparent;
    transition: all .3s;
}

.about .button-container .call-back a:hover,
.about .button-container .call-back span:hover {
    color: rgb(65, 126, 145);
    background: rgb(117, 251, 253);
    border: 2px solid #fff;
    transition: all .3s;
}

.about .image-container {
    max-width: 50%;
    justify-content: center;
}

.about .image-container img {
    text-align: center;
    height: 330px;
}

.about .counter-title {
    color: #fff;
    text-align: center;
    margin: 50px 0;
}

.about .counter-container {
    display: flex;
    flex-direction: row;
    text-align: center;
}

.about .counter-inner-container {
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 50%;
}

.about .counter-container li {
    width: 50%;
}

.about .counter-container li .counter {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 24px;
    color: rgb(117, 251, 253)
}

.about .counter-container li .title {
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 10px;
    font-size: 18px;
}

/*
    responsive
 */


@media only screen and (max-width: 850px) {

    .about .content-container {
        max-width: 100%;
    }

    .about .image-container {
        display: none;
    }

    .about .counter-inner-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
    }

    .about .counter-inner-container li:first-child {
        margin-bottom: 20px;
    }

    .about .counter-container li {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {

    .about .counter-container {
        max-width: 100%;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 450px) {

    .about .content-container {
        text-align: justify;
    }

    .about .button-container {
        justify-content: center;
    }
}