
.services {
    background: rgba(254, 249, 236);
    text-align: center;
    padding: 50px 0;
}

.services h1 {
    padding: 0 0 10px 0;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 800;
    color: rgb(23, 25, 58)
}

.services h2 {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 800;
    color: rgb(185, 145, 94);
    margin-bottom: 50px;
}

.services h3 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 800;
    color: rgb(23, 25, 58);
    text-align: left;
}

.services p {
    text-align: left;
    margin-top: 10px;
    line-height: 25px;
    width: 380px;
    color: rgb(23, 25, 58)
}

.services-highlight {
    color: rgb(185, 145, 94);
    margin-right: 5px;
    font-weight: 600;
}

.services-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.services-container .left {
    max-width: 50%;
}

.services-container .right {
    max-width: 50%;
}

.services-container .left img {
    margin: 40px;
    padding: 0;
    display: block;
}

.services-container .list-content {
    margin-top: 20px;
    margin-bottom: 50px;
    text-align: left;
}

.services-container .list-content ul {
    list-style: disc;
}

.services-container .list-content li {
    margin: 10px 20px;
}

.services-container .list-content li::marker {
    color: rgb(23, 25, 58);
}

.accordion {
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgb(23, 25, 58);
    color: #fff;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: .4s;
}

.accordion.start {
    border-radius: 10px 10px 0 0;
}

.accordion.end {
    border-radius: 0 0 10px 10px;
}

.active, .accordion:hover {
    background-color: rgba(23, 25, 58, .85);
}

.accordion.end.active {
    border-radius: 0;
}

.panel {
    padding: 20px;
    display: none;
    background-color: rgba(23, 25, 58, .1);
    overflow: hidden;
    color: rgba(23, 25, 58, 1);
    text-align: left;
    line-height: 25px;
}

.panel ul {
    list-style-type: square;
    list-style-position: inside;
}

.panel ul ul {
    list-style-type: disc;
    list-style-position: inside;
}
.panel ul li {
    padding: 0 20px;
}

.panel ul li::marker {
    color: rgb(185, 145, 94);
}

.panel p {
    width: 100%;
    color: rgba(23, 25, 58, 1);
    text-align: left;
    line-height: 25px;
}

.panel.end {
    border-radius: 0 0 10px 10px;
}

.accordion-container-1 {
    margin-bottom: 50px;
}

/*
    responsive
 */


@media only screen and (max-width: 850px) {

    .services-container .left,
    .services-container .right {
        max-width: 100%;
    }

    .services-container {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .services-container .left h3 {
        text-align: center;
        margin-bottom: 10px;
    }

    .services-container .services-left-inside {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
    }

    .services-container .services-left-inside .list-content {
        margin-top: 40px;
    }

    .services-container .services-left-inside img {
        margin-top: 10px;
    }
}

@media only screen and (max-width: 650px) {

    .services-container .services-left-inside img {
        width: 150px;
    }
}

@media only screen and (max-width: 550px) {
    .services-container .services-left-inside img {
        display: none;
    }

    .services-container .list-content li {
        text-align: justify;
    }
}
